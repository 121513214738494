import {
  usePlayer,
  usePlayers,
  useRound,
  useStage,
} from "@empirica/core/player/classic/react";
import { Loading } from "@empirica/core/player/react";
import React from "react";
import { Read } from "./stages/Read";
import { Play } from "./stages/Play";
import { Judge } from "./stages/Judge";

export function Stage() {
  const player = usePlayer();
  const players = usePlayers();
  const round = useRound();
  const stage = useStage();

  console.log("timer obj: ", stage.timer)

  if (player.stage.get("submit")) {
    if (players.length === 1) {
      console.log("player len 1 ", players)
      return <Loading />;
    }

    return (
      <div className="text-center text-gray-400 pointer-events-none">
        Please wait for other player(s).
      </div>
    );
  }

  console.log("stage: ", stage.get("name"), stage)

  switch (stage.get("name")) {
    case "read":
      console.log("read!")
      return <Read />;
    case "play":
      console.log("play!")
      return <Play />;
    case "judge":
      console.log("judge!!")
        return <Judge />;
    default:
      return <Loading />;
  }
}