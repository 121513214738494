
export const THINKING_TIME = 5 
export const TOTAL_TIME = 15

export const BASE_RATE="$15/hr"

export const GAME_COUNT=5

export const BONUS="$0.20"

export const THINKING_TIME_RESPOND=30